<template>
  <div>
    <b-sidebar
      id="sidebar-edit-user-data"
      sidebar-class="sidebar-lg"
      :visible="userPersonalDataSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateUserPersonalDataSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 >Editar Dados Pessoais</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label="Nome"
              label-for="user-name"
            >
              <b-form-input
                id="user-name"
                v-model="name"
                :class="{ 'is-invalid': v$.name.$error }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label="E-mail"
              label-for="user-email"
            >
              <b-form-input
                id="user-email"
                v-model="email"
                type="email"
                :class="{ 'is-invalid': v$.email.$error }"
              />
              <div class="invalid-feedback">
                <span v-if="v$.email.$invalid">
                  O email informado é inválido
                </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label="Celular"
              label-for="user-cellPhone"
            >
              <vue-tel-input
                id="user-cellPhone"
                v-model="cellPhone"
                :class="{ 'is-invalid': v$.cellPhone.$error }"
              />
              <div class="invalid-feedback">
                <span v-if="v$.cellPhone.$invalid">
                  Você deve informar um telefone válido
                </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label="CPF"
              label-for="user-cpf"
            >
              <b-form-input
                id="user-cpf"
                v-mask="'###.###.###-##'"
                v-model="cpf"
                :class="{ 'is-invalid': v$.cpf.$error }"
              />
              <div class="invalid-feedback">
                <span v-if="v$.cpf.$invalid">
                  O CPF informado é inválido
                </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label="RG"
              label-for="user-rg"
            >
              <b-form-input
                id="user-rg"
                v-model="rg"
                :class="{ 'is-invalid': v$.rg.$error }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label="Data de nascimento"
              label-for="user-birth-date"
            >
              <b-form-input
                id="user-birth-date"
                v-model="birthDate"
                v-mask="'##/##/####'"
                :class="{ 'is-invalid': v$.birthDate.$error }"
              />
              <div class="invalid-feedback">
                <span v-if="v$.birthDate.minLength.$invalid">
                  A data informada é inválida
                </span>
                <span v-if="v$.birthDate.isLegalAge.$invalid">
                  O cliente precisa ser maior de idade
                </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label="Profissão"
              label-for="user-occupation"
            >
              <b-form-input
                id="user-occupation"
                v-model="occupation"
                :class="{ 'is-invalid': v$.occupation.$error }"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12" md="12">
            <b-form-group
              label="Profissão"
              label-for="user-occupation"
            >
              <v-select
                id="user-occupation"
                v-model="occupation"
                :reduce="lead_occupation => lead_occupation.id"
                :options="occupations"
                :loading="loading.occupations"
                label="name"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row v-if="!incomeBracket">
          <b-col cols="12" md="12">
            <b-form-group
              label="Expectativa de renda"
              label-for="user-income"
            >
              <v-select
                id="user-income"
                v-model="income"
                :reduce="lead_income => lead_income.id"
                :options="incomes"
                :loading="loading.incomes"
                label="name"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Form Actions -->
        <div class="d-flex mt-2 justify-content-between">
          <b-button
            :disabled="saving"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="hide"
            class="edit-buttons"
          >
            Voltar
          </b-button>
          <b-button
            :disabled="saving"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="edit-buttons"
          >
            {{ saving ? 'Salvando...' : 'Salvar' }}
          </b-button>
        </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, 
  BForm, 
  BFormGroup, 
  BFormFile, 
  BAvatar, 
  BButton, 
  BFormInvalidFeedback, 
  BFormTextarea,
  BFormInput,
  BCol,
  BRow
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import DynamicSelect from '@/modules/shared/components/DynamicSelect'
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'
import { onlyDigits, dateToString, stringToDate } from '../../../helpers/converters'
import cpfIsValid from '../../../validators/cpf'
import { isLegalAge } from '../../../validators/date'



export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BCol,
    BRow,
    vSelect,
    DynamicSelect,
    
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: {
        occupations: false,
        incomes: false,
      },
      name: undefined,
      email: undefined,
      cellPhone: undefined,
      rg: undefined,
      cpf: undefined,
      birthDate: undefined,
      occupation: undefined,
      income: undefined,
      incomeBracket: undefined,
      saving: false,
    }
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations(){
    return {
      name: { required },
      cellPhone: {
        minLength (value){
            return value.length >= 9;
        },
        maxLength (value){
          return value.length <= 20;
        }
      },
      email: { email },
      rg: {},
      cpf: {
        valid (value) {
          return !value || cpfIsValid(value)
        }
      },
      birthDate: {
        minLength: minLength(10),
        isLegalAge (value) {
          return isLegalAge(value)
        }
      },
      occupation: {},
      income: {},
      userPersonalDataSidebar: {
        user:{
          id: { required }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      userPersonalDataSidebar: types.USER_PERSONAL_DATA_SIDEBAR,
      occupations: sharedTypes.OCCUPATIONS,
      incomes: sharedTypes.INCOMES,
    })
  },
  mounted() {
    this.loading.occupations = true
    this.getOccupations()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as profissões para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.occupations = false
      })
    this.loading.incomes = true
    this.getIncomes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as faixas de renda para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.incomes = false
      })
  },
  methods: {
    ...mapMutations({
      mutateUserPersonalDataSidebar: types.MUTATE_USER_PERSONAL_DATA_SIDEBAR
    }),
    ...mapActions({
      getOccupations: sharedTypes.GET_OCCUPATIONS,
      getIncomes: sharedTypes.GET_INCOMES,
      updateCustomerPersonalData: types.UPDATE_CUSTOMER_PERSONAL_DATA
    }),
    onShow(){
      var userData = this.userPersonalDataSidebar.user
      if(!userData){
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao editar os dados pessoais. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }else{
        this.name = userData.name
        this.email = userData.email
        this.cellPhone = userData.cell_phone?? undefined
        this.rg = userData.rg
        this.cpf = userData.cpf
        this.birthDate = dateToString(userData.birth_date)
        this.occupation = userData.occupation
        this.income =  userData.customer_additionals.income.id
        this.incomeBracket = userData.customer_additionals.income_bracket 
        this.v$.$touch()
      }
    },
    async onSubmit() {
      this.saving = true
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      const {
        name,
        email,
        cellPhone,
        rg,
        cpf,
        birthDate,
        occupation,
        income
      } = this
      this.updateCustomerPersonalData({
        id: this.userPersonalDataSidebar.user.id,
        name,
        email,
        cellPhone: onlyDigits(cellPhone),
        rg,
        cpf: onlyDigits(cpf),
        birthDate: stringToDate(birthDate),
        occupation,
        income
      })
      .then(response =>{
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Sucesso!',
            text: 'Salvo com sucesso.',
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
        this.mutateUserPersonalDataSidebar({visible: false})
        this.userPersonalDataSidebar.saveAction()
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao salvar. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.saving = false
      })
    },
    clear(){
      this.name = undefined
      this.email = undefined
      this.cellPhone = undefined
      this.rg = undefined
      this.cpf = undefined
      this.birthDate = undefined
      this.occupation = undefined
      this.income = undefined
      this.v$.$reset();
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-tel-input{
  padding: 0.438rem 1rem;
}
</style>
<style lang="scss">
.edit-buttons{
  width: 10rem;
}
</style>