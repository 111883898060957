<template>
  <div id="user-profile" class="profile">
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about  -->
        <b-col lg="4" cols="12" order="1" order-lg="1" class="pb-2">
          <profile-about
            :profileType="profileType"
            :reload="reload"
            ref="about"
          />
        </b-col>

        <!-- content -->
        <b-col lg="8" cols="12" order="2" order-lg="2">
          <!-- navigation -->
          <div
            class="nav-bar"
            v-if="
              profileType == types.PROFILE_TYPE_CUSTOMER ||
              profileType == types.PROFILE_TYPE_LEAD
            "
          >
            <h4 class="mb-1">Navegação</h4>
            <b-nav pills>
              <b-nav-item
                v-if="profileType == types.PROFILE_TYPE_CUSTOMER || profileType == types.PROFILE_TYPE_LEAD"
                id="customer-tools"
                @click="setNavigation('customer-tools')"
                :active="navigation == 'customer-tools'"
                class="profile-nav mr-1 mb-1"
              >
                {{ profileType == types.PROFILE_TYPE_CUSTOMER ? 'Ferramentas do cliente' : 'Ferramentas do lead' }}
              </b-nav-item>
              <b-nav-item
                v-if="profileType == types.PROFILE_TYPE_LEAD"
                id="comments"
                @click="setNavigation('comments')"
                :active="navigation == 'comments'"
                class="profile-nav mr-1 mb-1"
              >
                Comentários
              </b-nav-item>
              <b-nav-item
                v-if="profileType == types.PROFILE_TYPE_CUSTOMER"
                id="contracts"
                @click="setNavigation('contracts')"
                :active="navigation == 'contracts'"
                class="profile-nav mr-1 mb-1"
              >
                Contratos
              </b-nav-item>
              <b-nav-item
                @click="setNavigation('appointments')"
                :active="navigation == 'appointments'"
                class="profile-nav mr-1 mb-1"
              >
                Agendamentos
              </b-nav-item>
              <b-nav-item
                @click="setNavigation('files')"
                :active="navigation == 'files'"
                class="profile-nav mb-1"
              >
                Arquivos
              </b-nav-item>
            </b-nav>
          </div>
          <!-- navigation -->
          <customer-tools v-if="navigation == 'customer-tools'" ref="customer-tools" :customer="customer" :customerType="profileType"/>
          <financial-plan v-if="navigation == 'customer-tools'" ref="customer-tools" :customer="customer" :customerType="profileType"/>
          <profile-contracts v-if="navigation == 'contracts'" ref="contracts" />
          <profile-appointments
            v-if="navigation == 'appointments'"
            ref="appointments"
          />
          <profile-files v-if="navigation == 'files'" ref="files" />
          <profile-comments
            v-if="
              navigation == 'comments' ||
              profileType == types.PROFILE_TYPE_CUSTOMER
            "
            ref="comments"
          />
          <profile-recommendations ref="recommendations" />
          <profile-timeline ref="timeline" />
        </b-col>
        <!-- content -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol, BNav, BNavItem } from "bootstrap-vue";
import { mapGetters } from "vuex";
import ProfileAbout from "./ProfileAbout.vue";
import ProfileComments from "./ProfileComments.vue";
import ProfileTimeline from "./ProfileTimeline.vue";
import ProfileAppointments from "./ProfileAppointments.vue";
import ProfileFiles from "./ProfileFiles.vue";
import ProfileContracts from "./ProfileContracts.vue";
import ProfileRecommendations from "./ProfileRecommendations.vue";
import CustomerTools from "@/modules/profile/components/CustomerTools.vue";
import FinancialPlan from "@/modules/profile/components/FinancialPlan.vue";
import * as profileTypes from "@/constants/profile";
import * as storeType from "@/modules/profile/store/types";

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BNav,
    BNavItem,
    ProfileAbout,
    ProfileComments,
    ProfileTimeline,
    ProfileAppointments,
    ProfileFiles,
    ProfileContracts,
    ProfileRecommendations,
    CustomerTools,
    FinancialPlan,
  },
  props: {
    profileType: {
      type: String,
      default: profileTypes.PROFILE_TYPE_LEAD,
    },
  },
  data() {
    return {
      navigation: undefined,
      profileData: {},
    };
  },
  computed: {
    ...mapGetters({
      customer: storeType.CUSTOMER
    }),
    types: function () {
      return profileTypes;
    },
  },
  created() {
    this.$http.get("/profile/data").then((res) => {
      this.profileData = res.data;
    });
  },
  mounted() {
    if (this.profileType == profileTypes.PROFILE_TYPE_LEAD) {
      this.navigation = "comments";
    } else if (this.profileType == profileTypes.PROFILE_TYPE_CUSTOMER) {
      this.navigation = "contracts";
    } else if (this.profileType == profileTypes.PROFILE_TYPE_CONSULTANT) {
      this.navigation = "comments";
    }
  },
  methods: {
    setNavigation(component) {
      this.navigation = component;
    },
    reload() {
      if (this.navigation == "contracts") {
        this.$refs.contracts.get();
      }
      if (this.navigation == "appointments") {
        this.$refs.appointments.get();
      }
      if (
        this.navigation == "comments" ||
        this.profileType == types.PROFILE_TYPE_CUSTOMER
      ) {
        this.$refs.comments.get();
      }
      this.$refs.timeline.get();
      this.$refs.about.getProfileData();
    },

  goToFinancialPlanning() {
    this.$router.push({
      name: "financial-planning",
      params: { id: this.customer?.id },
    });
  },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";

.dark-layout .profile-nav {
  background-color: $theme-dark-card-bg !important;
}
.profile-nav {
  width: 15rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #ffff;
}

.nav-bar ul {
  margin-bottom: 0 !important;
}
</style>
