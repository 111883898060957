<template>
  <div>
    <!-- Section when current step is 1 -->
    <div
      v-if="customer.financial_planning_current_step === 1"
      role="alert"
      class="alert alert-warning mb-2 div-1"
      :class="{ clickable: canEditPlanoFinanceiro }"
      @click.prevent="handlePlanoFinanceiroClick"
    >
      <div class="alert-body text-center">
        <strong>Plano financeiro</strong>
        <p>Este cliente ainda não possui plano financeiro</p>
      </div>
    </div>

    <!-- Section when current step > 1 and not finished -->
    <div
      v-else-if="customer.financial_planning_current_step > 1 && !customer.financial_planning_finished_at"
      role="alert"
      class="alert alert-warning mb-2 div-2"
      :class="{ clickable: canEditPlanoFinanceiro }"
      @click.prevent="handlePlanoFinanceiroClick"
    >
      <div class="alert-body text-center">
        <strong>Plano financeiro</strong>
        <p>
          A construção do plano financeiro deste cliente foi iniciada
          <span v-if="customer.financial_planning_started_at">
            em {{ customer.financial_planning_started_at | dateTime }}
          </span>, mas ainda não foi concluída
        </p>
      </div>
    </div>

    <!-- Section when finished but not sent -->
    <b-card
      v-else-if="customer.financial_planning_finished_at && !customer.financial_planning_sent_at"
      class="div-3"
      :class="{ clickable: canEditPlanoFinanceiro }"
      @click.prevent="handlePlanoFinanceiroClick"
    >
      <b-row>
        <b-col>
          <h4>Plano financeiro</h4>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <p v-if="customer.financial_planning_started_at">
            Construção iniciada em: <strong>{{ customer.financial_planning_started_at | dateTime }}</strong>
          </p>
          <p>
            Finalizado em: <strong>{{ customer.financial_planning_finished_at | dateTime }}</strong>
          </p>
          <p>
            Enviado para o cliente em: <strong>{{ customer.financial_planning_sent_at | dateTime }}</strong>
          </p>
          <p class="fail">
            O cliente ainda não confirmou o recebimento do plano financeiro
          </p>
        </b-col>
      </b-row>
    </b-card>

    <!-- Section when customer read receipt is missing -->
    <b-card
      v-else-if="!customer.financial_planning_customer_read_receipt_id"
      class="div-4"
      :class="{ clickable: canEditPlanoFinanceiro }"
      @click.prevent="handlePlanoFinanceiroClick"
    >
      <b-row>
        <b-col>
          <h4>Plano financeiro</h4>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <p v-if="customer.financial_planning_started_at">
            Construção iniciada em: <strong>{{ customer.financial_planning_started_at | dateTime }}</strong>
          </p>
          <p>
            Finalizado em: <strong>{{ customer.financial_planning_finished_at | dateTime }}</strong>
          </p>
          <p>
            Enviado para o cliente em: <strong>{{ customer.financial_planning_sent_at | dateTime }}</strong>
          </p>
          <p class="fail">
            O cliente ainda não confirmou o recebimento do plano financeiro
          </p>
        </b-col>
      </b-row>
    </b-card>

    <!-- Section when customer read receipt is present -->
    <b-card
      v-else
      class="div-5"
      :class="{ clickable: canEditPlanoFinanceiro }"
      @click.prevent="handlePlanoFinanceiroClick"
    >
      <b-row>
        <b-col>
          <h4>Plano financeiro</h4>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <p v-if="customer.financial_planning_started_at">
            Construção iniciada em: <strong>{{ customer.financial_planning_started_at | dateTime }}</strong>
          </p>
          <p>
            Finalizado em: <strong>{{ customer.financial_planning_finished_at | dateTime }}</strong>
          </p>
          <p>
            Enviado para o cliente em: <strong>{{ customer.financial_planning_sent_at | dateTime }}</strong>
          </p>
          <p class="confirmed">
            Confirmação de recebimento pelo cliente em: {{ customer.financial_planning_read_receipts_created_at | dateTime }}
          </p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { UPDATE_ACTION, PLANO_FINANCEIRO } from "@/constants/resources";
import { BCard, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canEditPlanoFinanceiro() {
      return this.$can(UPDATE_ACTION, PLANO_FINANCEIRO);
    },
  },
  methods: {
    handlePlanoFinanceiroClick() {
      if (this.canEditPlanoFinanceiro) {
        this.goToPlanoFinanceiro();
      }
    },
    goToPlanoFinanceiro() {
      this.$router.push({
        name: "financial-planning",
        params: { id: this.customer.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
.alert p,
.card p {
  font-weight: bold;
}
.card .confirmed {
  color: #28c76f !important;
}
.card .fail {
  color: #ff9f43 !important;
}
</style>
